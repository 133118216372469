import { faAngleLeft, faClose, faEye, faMagnifyingGlass, faPen, faPlug, faPlus, faShare, faShareAlt, faTrash, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { apiUrl } from '../../comps/Config';
import { StaticDialog } from 'react-st-modal';
import { toast } from 'react-toastify';
import { getCookie } from '../../comps/getCookie';


class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
    data:[],
    loader:true,
    busca:'',
    search:undefined,
    trash:undefined,
    currentPage: 1,
    totalItems: 0,
    itemsPerPage: 15,
    };
  }

  componentDidMount(){
document.title = 'Home'
this.fetchData(this.state.currentPage);
  }

 formatarDataBR(dataString) {
    const data = new Date(dataString);
  
    // Verifica se a data é válida
    if (isNaN(data)) {
      throw new Error('O formato da data fornecida é inválido.');
    }
  
    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0'); // Meses começam em 0
    const ano = data.getFullYear();
  
    return `${dia}/${mes}/${ano}`;
  }




  fetchData(page) {
  
    
    fetch(`${apiUrl}/results?page=${page}&limit=${this.state.itemsPerPage}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getCookie('auth_token')}`,
      }
    })
      .then(response => {
        response.json().then(data=>{
         
    
    
    
    
          const totalItems = data.totalItems; // Simulado, substitua pelo valor real do backend.
            const totalPages = Math.ceil(totalItems / this.state.itemsPerPage);
  
            this.setState({
              items: data.items,
              currentPage: page,
              totalPages,
              loader: false,
            });


        })
      })

    
  }

  handlePageClick = (page) => {
    this.fetchData(page);
  };
  handleNextPage = () => {
    this.fetchData(this.state.currentPage + 1);
  };

  handlePreviousPage = () => {
    if (this.state.currentPage > 1) {
      this.fetchData(this.state.currentPage - 1);
    }
  };


  renderPagination() {
    const { currentPage, totalPages } = this.state;

    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => this.handlePageClick(i)}
          style={{
            margin: '0 5px',
            padding: '5px 10px',
            backgroundColor: i === currentPage ? '#007bff' : '#fff',
            color: i === currentPage ? '#fff' : '#000',
            border: '1px solid #ddd',
            cursor: 'pointer',
          }}
        >
          {i}
        </button>
      );
    }

    return (
      <div>
        <button
          onClick={() => this.handlePageClick(currentPage - 1)}
          disabled={currentPage === 1}
          style={{
            margin: '0 5px',
            padding: '5px 10px',
            cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
          }}
        >
          &lt;
        </button>
        {pages}
        <button
          onClick={() => this.handlePageClick(currentPage + 1)}
          disabled={currentPage === totalPages}
          style={{
            margin: '0 5px',
            padding: '5px 10px',
            cursor: currentPage === totalPages ? 'not-allowed' : 'pointer',
          }}
        >
          &gt;
        </button>
      </div>
    );
  }

  render() {
    if(this.state.loader){
      return(<div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100vh'}}>
        <div className='loader'>
        <div class="spinner"></div>
        </div>
        </div>)
    }
    var {data} = this.state;
    const { items, currentPage, isLoading, error } = this.state;
    return (<>
   
<div className='py-12'>
<div className='max-w-7xl mx-auto sm:px-6 lg:px-8'>
<div className='col-12'>
        <div class="">
        <div class="card">

        <div class="card-header d-flex align-items-center justify-content-between">
        <strong>Árvore</strong>
        <button className='btn btn-primary' onClick={()=>{
          this.props.navigateTo('/nova-arvore')
        }}>
          <FontAwesomeIcon icon={faPlus}/> Nova árvore
        </button>
        </div>


      <div class="card-body">
    
        <div class="mb-3">
          <div class="input-group" style={{
            
          }}>
           
            <input type="text" class="form-control" id="searchInput" onChange={(e)=>{
             if(e.target.value.length == 0){
              this.setState({search:undefined})
              return;
             }
             this.setState({busca:e.target.value})
            }} placeholder="Digite para buscar..."/>
            <button class="btn-psq3">
              <FontAwesomeIcon icon={faClose}/>
            </button>
            <button class="btn btn-primary" onClick={(e)=>{
              var value = this.state.busca;
         
              if(value.trim().length == 0){
                return;
              }

              e.target.classList.add('load')

              fetch(`${apiUrl}/buscar/`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  // outras headers, se necessário
                },
                body: JSON.stringify({
                value
                }), // corpo da requisição em formato JSON
              })
                .then(response => {
                 
                  e.target.classList.remove('load')  
                 

if(response.status == 200){
response.json().then(search=>{
  this.setState({search})
})
}


                })


            }}>
           <FontAwesomeIcon icon={faMagnifyingGlass}/>
            </button>
          </div>
        </div>
        <div id="resultList">
      
      {this.state.search == undefined ? <>






        {items.length > 0 ? <>
          
        <table class="table table-striped table-bordered arvores-l">
    <thead class="thead-dark">
      <tr>
        <th>Id</th>
        <th>data</th>
        <th>sobrenome</th>
      
        {localStorage.getItem("master") == "true" && <>
          <th>Usuário</th>
        </> }

        {localStorage.getItem("master") == "true" && <>
          <th>assessor</th>
        </> }
      <th>Nome do requerente</th>
      <th>Opções</th>

      </tr>
    </thead>
    <tbody>
    {items.map((item)=><>
    <tr>
  <td style={{textAlign:'center'}}>{item.psc}</td>
  <td>{this.formatarDataBR(item.dtn)}</td>
 <td style={{textAlign:'center'}}> {item.sbn != null ? item.sbn : 'Sobrenome a definir'}</td>
 {localStorage.getItem("master") == "true" &&  <td style={{textAlign:'center'}}>{item.user}</td>  }
 {localStorage.getItem("master") == "true" &&  <td style={{textAlign:'center'}}>{item.assr}</td>  }



 <td style={{textAlign:'center'}}>{item.nreq}</td>


<td style={{display:'flex',justifyContent:'center'}}>
  <div className='btn-group btn-group-sm'>
  <button className='btn btn-success' onClick={()=>{
                  this.props.navigateTo("/arvore/"+item.arvore_id)
                }}>
    <FontAwesomeIcon icon={faEye}/>
  </button>

  <button className='btn btn-info' onClick={()=>{
                  this.props.navigateTo(`/arvore/${item.arvore_id}/edit`)
                }}>
    <FontAwesomeIcon color='#fff' icon={faPen}/>
  </button>

  <button className='btn btn-danger' onClick={()=>{
                 this.setState({trash:item})
                }}>
    <FontAwesomeIcon icon={faTrash}/>
  </button>
  </div>

</td> 
    
    
    
    </tr>
    </>)}
    </tbody>
    </table>
          </> : <>
          Sem resultados
          </>}
      </> : <>
      
     {this.state.search.length > 0 ? <>
     
      <div class="list-group">
              {this.state.search.map((item)=><>
                <div class="list-group-item" onClick={()=>{
                  this.props.navigateTo("/arvore/"+item.id)
                }}>  
                <span>{item.sbn != '' ? item.sbn : 'Sobrenome a definir'}</span>
                 </div>
              </>)}
            </div>
     
     </> : <>Não foram encontrados resultados!</>}
         
      </>}
          
          
        
        </div>
      </div>
      <div className='card-footer' style={{display:'flex',justifyContent:'center'}}>
 {this.renderPagination()}
      </div>
    </div>
        </div>
  
</div>
      
   

    
   
</div>
</div>

<StaticDialog
        isOpen={this.state.trash != undefined}
        title="Tem certeza de que deseja continuar?"
        onAfterClose={(result) => {
 this.setState({trash:undefined})
          // do something with dialog result
        }}
    >
      <div style={{margin:10}}>
<span>
Se você confirmar a exclusão, não será possível recuperar os dados posteriormente.
</span>
      </div>
      <div className='modal-btn'>
<button className='btn btn-danger' onClick={(e)=>{

var trash = this.state.trash;


 e.target.classList.add('load')

 fetch(`${apiUrl}/deletar-arvore/`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
 'Authorization': `Bearer ${getCookie('auth_token')}`,
  },
  body: JSON.stringify({
  id:trash.arvore_id
  }),
})
  .then(response => {
   
    
    e.target.classList.remove('load')

if(response.status == 200){
  this.setState((prevState) => ({
    items: prevState.items.filter(item => item.arvore_id !== trash.arvore_id),
    trash:undefined
  }));
toast.success('Árvore deletada!')
}

}).catch(err=>{
  e.target.classList.remove('load')
toast.error("Falha de comunicação com servidor!")
})




}}>
  <FontAwesomeIcon icon={faTrash}/> Excluir
</button>
      </div>
    </StaticDialog>


        </>
    );
  }
}

export default Home;