import React, { Component } from 'react';
import logo from "../../../src/images/logo-c.png"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, NavLink } from 'react-router-dom';
import { apiUrl } from '../../comps/Config';
import male from "../../images/male.png"
import female from "../../images/female.png"
import br from "../../images/br.png"
import pt from "../../images/pt.png"
import es from "../../images/es.png"
import it from "../../images/it.png"
import po from "../../images/po.png"
import casa from "../../images/casa.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
class PreView2 extends Component {
  constructor(props) {
    super(props);
    this.globalIndex = 0; // Inicializa o contador global
    this.currentIndex = 0
    this.itemIndex = 0
    this.state = {
    loader:true,

    };
  }



  componentDidMount(){
    document.title = "Editar árvore"
    const rootElement = document.getElementById('root');
    if(rootElement){
      rootElement.classList.remove("fixa")
    }
    
    
      
    fetch(`${apiUrl}/listar-arvore/${window.location.pathname.split("/")[2]}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // outras headers, se necessário
      }
    })
      .then(response => {
        if(response.status == 404){
          this.setState({error:true,loader:false})
          return;
        }
    
    
    
    if(response.status == 200){
      response.json().then(data=>{
        this.setState({loader:false,tree:data})
        })
    }
      }).catch(err=>{
        toast.error("Falha de comunicação com o servidor!")
      })
    
    
    
    
      }


      Bandeira(e){
        switch(e){
          case 'Brasil':
          return <img src={br}/>
      
          case 'Itália':
          return <img src={it}/>
      
          case 'Portugal':
          return <img src={pt}/>
      
          case 'Espanha':
          return <img src={es}/>
      
          case 'Polonia':
          return <img src={po}/>
          
      
          
      
        }
      }
      

     FormatD(dataISO) {
        return dataISO.split('-').reverse().join('/');
    }



     
      formatarData = (data) => {
        if(data == null){
            return(<>Não informado</>)
        }
        const [ano, mes, dia] = data.split('-');
        return `${dia}/${mes}/${ano}`;
      }

    




      FindByReq(data, reqValue) {
        let result = [];
    
        function search(children, ancestors = []) {
            if (!Array.isArray(children)) return; // Verifica se `children` é um array
    
            for (const item of children) {
                // Adiciona o item atual na lista de ancestrais temporária
                const currentAncestors = [...ancestors, item];
    
                // Verifica se o item corresponde ao `reqValue`
                if (item.req === reqValue) {
                    // Traz os dois itens anteriores, se existirem
                    const startIndex = Math.max(0, currentAncestors.length - 3);
                    result = currentAncestors.slice(startIndex);
                    return; // Encerra a busca
                }
    
                // Busca recursivamente nos filhos
                if (Array.isArray(item.children) && item.children.length > 0) {
                    search(item.children, currentAncestors);
                }
            }
        }
    
        search(data);
        return result;
    }
  


    TreeNode = ({ node, x, y ,cjg}) => {
      if(cjg){
       return (
         <div
           style={{
             position: 'absolute',
             top: y,
             left: x,
             width: '110px',
             height: '160px',
             backgroundColor:'#fff',
             border: `2px solid ${node.code == this.state.selected ? 'blue': '#ccc'}`,
             textAlign: 'center',
             padding: '10px',
             borderRadius:'10px',
             boxSizing: 'border-box',
             filter: node.flc ? 'brightness(90%)' : 'brightness(100%)'
           }} onClick={()=>{
          
           this.setState({selected:node.code})
           }}
         >
   
           <div class="ft-user">
            
           {node.genero == null ? '' : node.genero === "f" ? <>
           <img src={female} className='user'/>
           </>: <>
           <img src={male} className='user'/>
           </>}
           
           
            <div className='ncdd'>
            {this.Bandeira(node.ncdd)}
            </div>
           </div>
           
   
   
           <div className='mt-2'>
   <p style={{fontSize:12}}>{node.label} {node.sobrenome}</p>
   
      
   <strong style={{fontSize:12}}>{node.req}</strong>
   </div>
   
      
   
        
   
   
   
   
         </div>
       );
      }else{
       return (
         <div
           style={{
             position: 'absolute',
             top: y,
             left: x,
             width: '110px',
             height: '160px',
             backgroundColor:'#fff',
             border: `2px solid ${node.id == this.state.selected ? 'blue': '#ccc'}`,
             textAlign: 'center',
             padding: '10px',
             borderRadius:'10px',
             boxSizing: 'border-box',
             filter: node.flc ? 'brightness(90%)' : 'brightness(100%)'
           }}
           onClick={()=>{
           
             this.scrollToItem(node.id)

             this.setState({selected:node.id})
             }}
         >
           <div class="ft-user">
            
           {node.genero == null ? '' : node.genero === "f" ? <>
           <img src={female} className='user'/>
           </>: <>
           <img src={male} className='user'/>
           </>}
           
           
            <div className='ncdd'>
            {this.Bandeira(node.ncdd)}
            </div>
           </div>
           
   
   <div className='mt-2'>
   
   {(node.genero == "f" && node.cjg != undefined) ? <div>
   
   
   {(node.cjg.dcasada != null && node.cjg.dcasada != '') ? <>{node.cjg.dcasada}</> :  <p style={{fontSize:12}}>{node.label} {node.sobrenome}</p>}
   
   
   </div>:<div>
     <p style={{fontSize:12}}>{node.label} {node.sobrenome}</p>
   </div>}
   
   
   
   
   
   
   
      
   <strong style={{fontSize:12}}>{node.req}</strong>
   </div>
   
         
           
   
   
   
   
         </div>
       );
      }
   
   
     };
     
    Tree = ({ nodes, parent, x, y, spacing, cjg,children }) => {
       const totalWidth = (nodes.length - 1) * spacing;
       const startX = x - totalWidth / 2;
     
       return (
         <>
           {nodes.map((node, index) => {
             const newX = startX + index * spacing;
             const newY = y + 200;
     
             return (
               <React.Fragment key={node.id}>
                 {/* Linha que liga o nó atual ao nó pai */}
                
                 {parent && <>
                 
                   {node.cjg ? <>
                   
                     <div
                         style={{
                           position: 'absolute',
                           top: y+168,
                           left: x+130,
                           width: `80px`,
                           height: `114px`,
                           borderLeft: '2px solid #9ea1a1',
                           
                         }}
                       />
                   
                   </>:<>
                 
                     <div
                         style={{
                           position: 'absolute',
                           top: y+168,
                           left: x+50,
                           width: `80px`,
                           height: `32px`,
                           borderLeft: '2px solid #9ea1a1',
                           borderTop: '2px solid #9ea1a1',
                         }}
                       />
                   
                   </>}
              
                 
                 </>}
     
     
     
               
   
   
             
                 <this.TreeNode node={node} cjg={cjg} x={newX} y={newY} />
           
   
        
            
   
   
                
   
                 {/* Renderizar filhos do nó atual */}
              
                 {node.cjg && (
                 <>
                   <this.Tree nodes={[node.cjg]} children={node.children}  cjg={true} x={newX + 150} y={newY-200} spacing={spacing / 2} />
                  <div
                         style={{
                           position: 'absolute',
                           top: y+280,
                           left: x+110,
                           width: `40px`,
                           height: `1px`,
                           borderLeft: '2px solid #9ea1a1',
                           borderTop: '2px solid #9ea1a1',
                         }}
                       />
             
         
              {node.children != undefined && node.children.length > 0 && <>
               
               
          
   
   
               
   
               
               <div
                         style={{
                           position: 'absolute',
                           top: y+280,
                           left: x+130,
                           width: `1px`,
                           height: `110px`,
                           borderLeft: '2px solid #9ea1a1',
                           borderTop: '2px solid #9ea1a1',
                         }}
                       />
                           <div
                         style={{
                           position: 'absolute',
                           top: y+388,
                           left: x+30,
                           width: `100px`,
                           height: `1px`,
                           borderLeft: '2px solid #9ea1a1',
                           borderTop: '2px solid #9ea1a1',
                         }}
                       />
              </>}
                        
                 </>
                 )}
     
                 {node.children && (<>
                   <this.Tree nodes={node.children} parent={node} x={newX - 100} y={newY+20} cjg={false} spacing={spacing / 2} />
               {node.cjg == undefined && <>
            
              {node.children != undefined && node.children.length > 0 && <>
               <div
                         style={{
                           position: 'absolute',
                           top: y+360,
                           left: x-20,
                           width: `80px`,
                           height: `30px`,
                           borderRight: '2px solid #9ea1a1',
                           borderBottom: '2px solid #9ea1a1',
                         }}
                       >
   
   
                     
                       </div>
              </>}
                        
                      
               
               </>}
                </>
                 )}
     
               </React.Fragment>
             );
           })}
         </>
       );
     };

    ShowParentes(req){

        
 
      var array = this.FindByReq(this.state.tree,req)
     
      let avos = {
          avos_paternos: {},
          avos_maternos: {},
          pais:{}
        };
      var eu = array[array.length - 1]  != undefined ? array[array.length - 1] : []
      var acm = (array[array.length - 2])

 
      

      if(array.length == 2){

    
        avos.pais.pai = acm?.label ? acm.label + ' ' + (acm.sobrenome || '') : '';
        avos.pais.mae = acm.cjg ? (acm.cjg.label ? acm.cjg.label + ' ' + acm.cjg.sobrenome : acm.cjg.sobrenome || '') : '';
      
        avos.avos_paternos.avoh = acm?.pai ? acm.pai + ' ' + (acm.spai || '') : '';
        avos.avos_paternos.avom = acm?.mae ? acm.mae + ' ' + (acm.smae || '') : '';
      
        avos.avos_maternos.avoh = acm.cjg ? (acm.cjg.pai ? acm.cjg.pai + ' ' + acm.cjg.spai : acm.cjg.spai || '') : '';
        avos.avos_maternos.avom = acm.cjg ? (acm.cjg.mae ? acm.cjg.mae + ' ' + acm.cjg.smae : acm.cjg.smae || '') : '';
        
      
      }else if(array.length == 3){
      if(acm.genero == "m"){
      
      
        var prn = (array[array.length - 3])
        var mtn = (array[array.length - 2])
        
      
       
      
        avos.pais.pai = mtn?.label ? mtn.label + ' ' + (mtn.sobrenome || '') : '';
       
        if (mtn && mtn.cjg && mtn.cjg.dcasada !== undefined) {
          avos.pais.mae = mtn.cjg.label + ' ' + mtn.cjg.dcasada;
        } else {
          avos.pais.mae = mtn && mtn.cjg 
            ? (mtn.cjg.label ? mtn.cjg.label + ' ' + mtn.cjg.sobrenome : mtn.cjg.sobrenome || '') 
            : '';
        }
      
      
        
       if(prn.cjg != undefined && prn.cjg.dcasada !=null && prn.cjg.dcasada != '' ){
        avos.avos_paternos.avoh = prn.cjg.dcasada
       }else{
        avos.avos_paternos.avoh = prn?.label ? prn.label + ' ' + (prn.sobrenome || '') : '';
       }
      
      
      
        avos.avos_paternos.avom = prn.cjg ? (prn.cjg.label ? prn.cjg.label + ' ' + prn.cjg.sobrenome : prn.cjg.sobrenome || '') : '';
        
      
      
        avos.avos_maternos.avoh = mtn.cjg ? (mtn.cjg.pai ? mtn.cjg.pai + ' ' + mtn.cjg.spai : mtn.cjg.spai || '') : '';
        avos.avos_maternos.avom = mtn.cjg ? (mtn.cjg.mae ? mtn.cjg.mae + ' ' + mtn.cjg.smae : mtn.cjg.smae || '') : '';
      
      
      
      
      
      }else{
      
      
      
      
      
        var acm = (array[array.length - 3])
      
      
      
      
      
        var prn = (array[array.length - 2])
      
      if(prn.cjg != undefined && prn.cjg.dcasada !=null && prn.cjg.dcasada != '' ){
        avos.pais.mae = prn.cjg.dcasada
      }else{
        avos.pais.mae = prn?.label ? prn.label + ' ' + (prn.sobrenome || '') : '';
      }
      
      
      
        avos.pais.pai = prn.cjg ? (prn.cjg.label ? prn.cjg.label + ' ' + prn.cjg.sobrenome : prn.cjg.sobrenome || '') : '';
      
      
      
      
        
        
        if(prn.genero == "m"){
      
        avos.avos_maternos.avoh = "a1";
        avos.avos_maternos.avom = "a2";
        
      
      
          avos.avos_paternos.avoh = acm.cjg ? (acm.cjg.pai ? acm.cjg.pai + ' ' + acm.cjg.spai : acm.cjg.spai || '') : '';
          avos.avos_paternos.avom = acm.cjg ? (acm.cjg.mae ? acm.cjg.mae + ' ' + acm.cjg.smae : acm.cjg.smae || '') : '';
        
        
        }else{
      
      
        
      
          if((prn.genero || acm.genero)){
            
            avos.avos_maternos.avoh = acm.cjg ? (acm.cjg.label ? acm.cjg.label + ' ' + acm.cjg.sobrenome : acm.cjg.sobrenome || '') : ''; 
            avos.avos_maternos.avom = acm.cjg.dcasada;
        
          }else{
            avos.avos_maternos.avoh = acm?.label ? acm.label + ' ' + (acm.sobrenome || '') : '';
            avos.avos_maternos.avom = acm.cjg ? (acm.cjg.label ? acm.cjg.label + ' ' + acm.cjg.sobrenome : acm.cjg.sobrenome || '') : '';
        
          }
      
         
          avos.avos_paternos.avoh = prn.cjg ? (prn.cjg.pai ? prn.cjg.pai + ' ' + prn.cjg.spai : prn.cjg.spai || '') : '';
          avos.avos_paternos.avom = prn.cjg ? (prn.cjg.mae ? prn.cjg.mae + ' ' + prn.cjg.smae : prn.cjg.smae || '') : '';
                     
        
         }
         
      
      
      }
      
    
    }
    





if(array.length == 0){
  return undefined;
}else{
return(<div className='a4-item'>
<div class="area-l">


<div style={{display:'flex',alignItems:'center',marginBottom:20}}>

{
eu.genero == "m"  ? <img src={male} width={25} height={25} style={{marginRight:5}}/> :   <img src={female} width={25} height={25} style={{marginRight:5}}/>
}


<span>Denominação:<strong> {eu.req} (a)</strong> {eu.nmr != null && <>
- <strong>{eu.nmr}</strong>
</>}</span>
</div>

<div>
<label>Nome: <strong>{eu.label} {eu.sobrenome}</strong></label>
</div>





<div className='ncm'>
<div>
<span><span style={{color:'#2563eb',fontWeight:700}}>nascimento:</span> <strong>{eu.lnac != null ? eu.lnac : '---'}</strong></span>
{this.Bandeira(this.state.tree[0].ncdd)}</div>

<div>
Data: <strong>{eu.dnac != null ? this.formatarData(eu.dnac ): '---' }</strong>
</div>

{this.Bandeira(eu.ncdd)}
</div>

<div className='d-flex align-items-center '>
  <span>NOME DO PAI: <strong>{avos.pais.pai}</strong></span>
</div>

<div className='d-flex align-items-center '>
  <span>NOME Da mãe: <strong>{avos.pais.mae}</strong></span>
</div>



<div className='d-flex align-items-center '>
  <span>AVÔ PATERNO: <strong>{avos.avos_paternos.avoh}</strong></span>
</div>

<div className='d-flex align-items-center '>
  <span>AVÓ PATERNA: <strong>{avos.avos_paternos.avom}</strong></span>
</div>

<div className='d-flex align-items-center '>
  <span>AVÔ MATERNO: <strong>{avos.avos_maternos.avoh}</strong></span>
</div>

<div className='d-flex align-items-center '>
  <span>AVÓ MATERNA: <strong>{avos.avos_maternos.avom}</strong></span>
</div>





{
    eu.obs != null && <div>
    <span><span style={{color:'#2563eb',fontWeight:700}}>Observações:</span> <strong style={{color:"red"}}>{eu.obs}</strong></span>
    </div>
    }
    
    
    {
    eu.rtc != null && <div>
    <span><span style={{color:'#2563eb',fontWeight:700}}>Retificação:</span> <strong style={{color:"red"}}>{eu.rtc}</strong></span>
    </div>
    }



{eu.cjg != undefined && <>
  {eu.cjg.tipo == "c" ? <>



<div className='ncm'>
<div><span style={{color:'#2563eb',fontWeight:700}}>Casamento:</span> <strong>{eu.cjg.lcasa != null ? eu.cjg.lcasa : '---'}</strong></div>
<div>Data: <strong>{eu.cjg.dcasa != null ? this.formatarData(eu.cjg.dcasa) : '---'}</strong></div>
{this.Bandeira(eu.bcasa)}
</div>


</> : <>
<div>
<span>
<span style={{color:'#2563eb',fontWeight:700}}>Casamento:</span> <strong>NÃO HOUVE CASAMENTO</strong>
</span>
</div>

<div>
<span>
Estado Civil: <strong>{eu.status_c != null ? eu.status_c+ ' (a)' : '---'}</strong>
</span>
</div>
</>
}
</>}


</div>
{eu.cjg != undefined && <>

  <div class="area-r">

<div className='dados'>

<div style={{display:'flex',alignItems:'center',marginBottom:20}}>
  



{

eu.cjg.genero == "m" ?  <img src={male} width={25} height={25} style={{marginRight:5}}/> :   <img src={female} width={25} height={25} style={{marginRight:5}}/>

}
  <span>Denominação:<strong>  {eu.cjg.tipo == "c" ? "Cônjuge" : "COMPANHEIRO"} (a)</strong></span>
</div>


<div>
  <label>Nome: <strong>{eu.cjg.label} {eu.cjg.sobrenome}</strong></label>
 </div>




<div className='ncm'>
  <div><span style={{color:'#2563eb',fontWeight:700}}>nascimento:</span> <strong>{eu.cjg.lnac != null ? eu.cjg.lnac : '---'}</strong></div>
  <div>Data: <strong>{eu.cjg.dnac != null ? this.formatarData(eu.cjg.dnac) : '---' }</strong></div>
  {this.Bandeira(eu.cjg.ncdd)}
  </div>




{eu.cjg.pai != null && <div>
<span>Nome do Pai: <strong>{eu.cjg.pai} {eu.cjg.spai}</strong></span>
</div>}

{eu.cjg.mae != null && <div>
<span>Nome da mãe: <strong>{eu.cjg.mae} {eu.cjg.smae}</strong></span>
</div>}



</div>
</div>

</>}

    </div>)
}



       
    }
  

render() {

    var tree  = this.state.tree;






if(this.state.loader){
    return(<div style={{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        height:'100vh'
    }}>
Carregando...
    </div>)
}


var tree  = this.state.tree;



return(<div style={{background:"#fff"}}>


<div className="a4-page">
        <div style={{
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
          height:'60px'
        }}>

      <span style={{fontSize:20}}>ÁRVORE GENEALÓGICA FAMILIA: {tree[0].sobrenome}</span>
      <hr/>

        </div>
        
        <div style={{display:'table',margin:'auto',width:'95%',borderLeft:'solid 1px',borderRight:'solid 1px'}}>

            
        <div className='a4-item' style={{borderTop:'solid 1px'}}>

     
<div class="area-l">


<div style={{display:'flex',alignItems:'center',marginBottom:20}}>

{
this.state.tree[0].genero == "m"  ? <img src={male} width={25} height={25} style={{marginRight:5}}/> :   <img src={female} width={25} height={25} style={{marginRight:5}}/>
}



<span>Denominação:<strong> dante causa</strong> {this.state.tree[0].nmr != null && <>
- <strong>{this.state.tree[0].nmr}</strong>
</>}</span>
</div>

<div>
<label>Nome: <strong>{this.state.tree[0].label} {this.state.tree[0].sobrenome}</strong></label>
</div>

<div className='ncm'>
<div>
<span><span style={{color:'#2563eb',fontWeight:700}}>Nascimento:</span> <strong>{this.state.tree[0].lnac != null ? this.state.tree[0].lnac : '---'}</strong></span>
{this.Bandeira(this.state.tree[0].ncdd)}</div>

<div>
Data: <strong>{this.state.tree[0].dnac != null ? this.formatarData(this.state.tree[0].dnac ): '---' }</strong>
</div>
</div>

{this.state.tree[0].pai != null && <div>
<span>Nome do Pai: <strong>{this.state.tree[0].pai} {this.state.tree[0].spai}</strong></span>
</div>}


{this.state.tree[0].mae != null && <div>
<span>Nome da mãe: <strong>{this.state.tree[0].mae} {this.state.tree[0].smae}</strong></span>
</div>}


{this.state.tree[0].cjg.tipo == "c" ? <>



<div className='ncm'>
<div> <span style={{color:'#2563eb',fontWeight:700}}>Casamento:</span> <strong>{this.state.tree[0].cjg.lcasa != null ? this.state.tree[0].cjg.lcasa : '---'}</strong></div>
<div>Data: <strong>{this.state.tree[0].cjg.dcasa != null ? this.formatarData(this.state.tree[0].cjg.dcasa) : '---'}</strong></div>
{this.Bandeira(this.state.tree[0].bcasa)}
</div>


</> : <>
<div>
<span>
<span style={{color:'#2563eb',fontWeight:700}}>Casamento:</span> <strong>NÃO HOUVE CASAMENTO</strong>
</span>
</div>

<div>
<span>
Estado Civil: <strong>{this.state.tree[0].status_c != null ? this.state.tree[0].status_c+ ' (a)' : '---'}</strong>
</span>
</div>
</>
}


{this.state.tree[0].flc && <>
<div className='ncm'>
<div>
<span>Óbito: <strong>{this.state.tree[0].flc.local != null ? this.state.tree[0].flc.local : '---'}</strong></span>
{this.Bandeira(this.state.tree[0].ncdd)}</div>

<div>
Data: <strong>{this.state.tree[0].flc.data != null ? this.formatarData(this.state.tree[0].flc.data ): '---' }</strong>
</div>
</div>
</>}


{
this.state.tree[0].obs != null && <div>
<span><span style={{color:'#2563eb',fontWeight:700}}>Observações:</span> <strong style={{color:"red"}}>{this.state.tree[0].obs}</strong></span>
</div>
}


{
this.state.tree[0].rtc != null && <div>
<span><span style={{color:'#2563eb',fontWeight:700}}>Retificação:</span> <strong style={{color:"red"}}>{this.state.tree[0].rtc}</strong></span>
</div>
}


</div>



{this.state.tree[0].cjg && <>
<div class="area-r">

<div className='dados'>

<div style={{display:'flex',alignItems:'center',marginBottom:20}}>


{

this.state.tree[0].cjg.genero == "m" ?  <img src={male} width={25} height={25} style={{marginRight:5}}/> :   <img style={{marginRight:5}} src={female} width={25} height={25}/>

}


<span>Denominação:<strong>  {this.state.tree[0].cjg.tipo == "c" ? "Cônjuge" : "COMPANHEIRO"} (a)</strong></span>
</div>


<div>
<label>Nome: <strong>{this.state.tree[0].cjg.label} {this.state.tree[0].cjg.sobrenome}</strong></label>
</div>












<div className='ncm'>
<div><span style={{color:'#2563eb',fontWeight:700}}>Nascimento:</span> <strong>{this.state.tree[0].cjg.lnac != null ? this.state.tree[0].cjg.lnac : '---'}</strong></div>
<div>Data: <strong>{this.state.tree[0].cjg.dnac != null ? this.formatarData(this.state.tree[0].cjg.dnac) : '---' }</strong></div>
{this.Bandeira(this.state.tree[0].cjg.ncdd)}
</div>



{this.state.tree[0].cjg.pai != null && <div>
<span>Nome do Pai: <strong>{this.state.tree[0].cjg.pai} {this.state.tree[0].cjg.spai}</strong></span>
</div>}

{this.state.tree[0].cjg.mae != null && <div>
<span>Nome da mãe: <strong>{this.state.tree[0].cjg.mae} {this.state.tree[0].cjg.smae}</strong></span>
</div>}

{this.state.tree[0].cjg.tipo == "c" ? <>
</> : <>

</>}

</div>
</div>
</>
}




</div>



            {this.ShowParentes('Filho')}

            {this.ShowParentes('Neto')}

            {this.ShowParentes('Bisneto')}

      
       

            

      
</div>


</div>


{this.ShowParentes('Trineto') && <>



<div className="a4-page">

<div style={{display:'table',margin:'auto',marginTop:20,width:'95%',borderTop:'solid 1px',borderLeft:'solid 1px',borderRight:'solid 1px'}}>



{this.ShowParentes('Trineto')}



  {this.ShowParentes('Tataraneto')}



</div>
</div>






</>}



<div className="a4-page2" >

<div style={{
  
  transform:'scale(0.75)'
}}>
<this.Tree   nodes={[this.state.tree[0]]} parent={null} x={300} y={-110} spacing={0} />
</div>







</div>


</div>)




 
  }
}
// {tree[0].children.map((node) => this.renderTree(node))}
export default PreView2;